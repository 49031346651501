/**
 * @section : Unobtrusive JavaScript events and function calls, triggered on DOMContentLoaded.
 * @project : Hartstichting Reanimatiepartners
 * @author  : Iain van der Wiel <iain@e-sites.nl> & Tom Schalken <tom@e-sites.nl>
 */

(function (window, document, $, app) {

	'use strict';

	// Main cachebuster for lazyloaded scripts
	$script.urlArgs('bust=' + app.cachebuster);

	// toggle menu
	$('.toggle-menu').on('click', function(e){
		e.preventDefault();
		$('body').toggleClass('menu-visible');
	});

	$('.main-content').on('click', function(e){
		if($('body').hasClass('menu-visible')) {
			e.preventDefault();
			$('body').toggleClass('menu-visible');
		}
	});

	/**
	 * Main init function that kickstarts all site logic when the DOM is loaded
	 * Make sure all event handlers are placed within this function
	 *
	 * @private
	 */
	function _init() {

		// Init placeholder plugin
		$('input, textarea', 'form').placeholder();

		// Mimic autofocus for IE<10
		$('[autofocus]').autofocus();

		// Parsley based form validation
		app.util.initFormValidation('.js-validate-form');

		// Custom event tracking
		// Tracks external links and downloads by default
		// Use vestigo.init().debug(); to see all logs
		vestigo.init();

		$('.filepicker').each(function () {
			var $picker = $(this);

			$picker.filepicker({
				output: $picker.attr('id') + '_output'
			});
		});

		// iswitch toggle
		$('.iswitch').on('click change', function(e) {
			toggle($(this));
		});

		// super toggle
		$('.toggle-all.passed').on('click', function(e) {
			e.preventDefault();

			var $self = $(this),
				toggles = ($self.hasClass('yes'))? $('.iswitch.passed').not(':checked') : $('.iswitch.passed:checked');

			toggles.each(function() {
				$(this).click();
			});
		});

		function toggle (elem) {
			var $self = elem,
				$parent = $self.parent(),
				$label = $parent.children('.toggle-text'),
				$passed = $parent.children('.is-passed'),
				checked = $self.is(':checked');

			if (checked) {
				$label.text($label.data('checked'))
				$passed.val('1');
			} else {
				$label.text($label.data('unchecked'))
				$passed.val('0');
			}
		}

		// confirm deletion
		var $modal = $('#confirm-delete');

		$('[data-action="ask"]').on('click', function(e) {
			e.preventDefault();

			var $self = $(this),
				actiontype = $self.data('actiontype'),
				type = $self.data('type'),
				thing = $self.data('thing'),
				action = $self.attr('href'),
				title,
				confirmation,
				typeString,
				thing;

			// create title
			switch (type) {
				case 'course':
					typeString = 'cursus ';
					break;
				case 'person':
					typeString = 'cursist ';
					break;
				case 'location':
					typeString = 'locatie ';
					break;
				default:
					typeString = '';
					break;
			}

			// confirmation message
			switch (actiontype) {
				case 'delete':
					confirmation = 'Weet je zeker dat je ' + typeString + '&quot;' + thing + '&quot; wilt verwijderen?';
					title = typeString + '&quot;' + thing + '&quot; verwijderen';
					break;
				case 'pass':
					confirmation = 'Weet je zeker dat je alle cursisten wilt laten slagen?';
					title = 'Alle cursisten laten slagen';
					$('.delete.btn.btn-primary').text('Ja, toepassen');
					break;
			}

			title = title.charAt(0).toUpperCase() + title.slice(1);

			$modal.find('.modal-title').html(title);

			$modal.find('.modal-body').html(confirmation);

			// add appropriate link to button
			$modal.find('.delete').attr('href', action);

			// show modal
			$modal.modal('show', {backdrop: 'fade'});
		});

		// confirm deletion


		$('[data-action="pass"]').on('click', function(e) {
			var $modal = $('#confirm-pass');
			e.preventDefault();

			var $self = $(this),
				type = $self.data('type'),
				thing = $self.data('thing'),
				action = $self.attr('href'),
				actionNo = $self.data('href-no'),
				title,
				confirmation,
				typeString,
				thing;

			// add appropriate link to button
			$modal.find('.delete').attr('href', action);
			$modal.find('.js-btn-no-confirm').attr('href', actionNo);

			// show modal
			$modal.modal('show', {backdrop: 'fade'});
		});
		// clean up file inputs
		$('.filepicker').each(function() {
			var $fpCol = $(this).closest('.col-sm-10'),
				$fpLabel = $fpCol.prev();

			$fpLabel.remove();
			$fpCol.removeClass('col-sm-10').addClass('col-sm-12');
		});

		// fake fieldset titles, clean up upload input
		$('[data-fieldset-header]').each(function() {
			var $self = $(this),
				$headerContainer;

			if($self.hasClass('filepicker')) {
				$headerContainer = $self.closest('.form-group').parent().closest('.form-group');
			} else {
				$headerContainer = $self.closest('.form-group');
			}

			$headerContainer.prepend('<h3 class="fieldset-header">' + $(this).data('fieldset-header') + '</h3>');
		});

		/* DIY date picker because Bootstrap's one keep messing around */

		(function initDate() {
			$('.hidden-date').each(function() {
				var $self = $(this),
					dateValue = $self.val().split('-'),
					$inputGroup = $self.closest('.date-inputs'),
					$dayInput = $inputGroup.find('.day'),
					$monthInput = $inputGroup.find('.month'),
					$yearInput = $inputGroup.find('.year');

				if(dateValue.length === 3) {
					$dayInput.val(dateValue[2]);
					$monthInput.val(dateValue[1])
					$yearInput.val(dateValue[0]);
				}

				if($monthInput.val() === '00') {
					$monthInput.addClass('empty');
				} else {
					$monthInput.removeClass('empty');
				}
			});
		}());

		$('.form-control.day, .form-control.month, .form-control.year').on('keyup change focusout', upDate);

		function upDate() {
			var $self = $(this),
				$inputGroup = $self.closest('.date-inputs'),
				$realDate = $inputGroup.find('.hidden-date'),
				$dayInput = $inputGroup.find('.day'),
				$monthInput = $inputGroup.find('.month'),
				$yearInput = $inputGroup.find('.year'),
				dayValue = ('00'+ $dayInput.val()).substring($dayInput.val().length),
				monthValue = $monthInput.val() || '00',
				yearValue = ('0000'+ $yearInput.val()).substring($yearInput.val().length);

			$realDate.val(yearValue + '-' + monthValue + '-' + dayValue);

			if(monthValue === '00') {
				$monthInput.addClass('empty');
			} else {
				$monthInput.removeClass('empty');
			}
		}

		// General event delegation
		// Use this $(document) reference to chain other delegated events
		$(document)

			// Handle external links (the old way)
			.on('click', 'a[rel="external"]', app.util.setExtLinks)

			// Focus search box when pressing '/'
			.on('keyup', app.util.focusSearchBox);
	}

	// Initialize
	$(document)
		.ready(_init)
		.ajaxError(app.util.processXhrError);

}(window, document, jQuery, app));