/**
 * File picker with image preview for browsers that support the FileReader API
 * In older browsers it will display the file name instead
 *
 * @author Boye Oomens <boye@e-sites.nl>
 */

/**
 * Distillery namespace
 *
 * @type {Object}
 */

(function (window, document, $) {

	'use strict';

	/**
	 * File picker constructor
	 *
	 * @author Boye Oomens <boye@e-sites.nl>
	 * @param  {Object} options
	 * @constructor
	 */
	function Filepicker(options) {

		// We need at least some options and a target element to work with
		if ( !options || !options.target ) {
			return;
		}

		// Called as function
		if ( !(this instanceof Filepicker) ) {
			return new Filepicker(options);
		}

		/**
		 * Cross Browser helper for addEventListener.
		 *
		 * @param {HTMLElement} obj The Element to attach event to.
		 * @param {String} evt The event that will trigger the binded function.
		 * @param {Function(event)} fn The function to bind to the element.
		 * @return {Boolean} true if it was successfuly binded.
		 * @private
		 */
		function _addEventListener(obj, evt, fn) {
			if ( obj.addEventListener ) {
				obj.addEventListener(evt, fn, false);
				return true;
			} else if ( obj.attachEvent ) {
				return obj.attachEvent('on' + evt, fn);
			}
			return false;
		}

		/**
		 * Main picker namespace
		 *
		 * @type {Object}
		 */
		var picker = {

			/**
			 * Target element
			 *
			 * @type {[type]}
			 */
			target: options.target || null,

			/**
			 * Selected file reference
			 *
			 * @type {Object}
			 */
			file: null,

			/**
			 * The formatted filename
			 *
			 * @type {String}
			 */
			fileName: '',

			/**
			 * FileReader instance
			 *
			 * @type {Object}
			 */
			reader: null,

			/**
			 * Image preview?
			 *
			 * @type {Boolean}
			 */
			preview: options.preview || false,

			/**
			 * Output element
			 *
			 * @type {HTMLElement}
			 */
			output: options.output ? document.getElementById(options.output) : null,

			/**
			 * Binds the given event and invokes the
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @param  {String}   event event type
			 * @param  {Function} fn    callback
			 * @return {Object}         Eternity instance
			 */
			on: function (event, fn) {
				this._events = this._events || {};
				this._events[event] = this._events[event] || [];
				this._events[event].push(fn);

				return this;
			},

			/**
			 * Unbinds the given event
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @param  {String}   event event type
			 * @param  {Function} fn    callback
			 * @return {Object}         Eternity instance
			 */
			off: function (event, fct) {
				this._events = this._events || {};

				if ( event in this._events === false ) {
					return;
				}

				this._events[event].splice(this._events[event].indexOf(fct), 1);

				return this;
			},

			/**
			 * Triggers the given event
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @param  {[type]} event [description]
			 * @return {Object}       Eternity instance
			 */
			trigger: function (event) {
				this._events = this._events || {};

				if ( event in this._events === false  ) {
					return;
				}

				for (var i = 0; i < this._events[event].length; i++) {
					this._events[event][i].apply(this, Array.prototype.slice.call(arguments, 1));
				}

				return this;
			},

			/**
			 * Main init method that binds the event listener
			 *
			 * @return {Object}
			 */
			init: function () {
				_addEventListener(picker.target, 'change', picker.select);
				return picker;
			},

			/**
			 * Checks of the given filename matches one of the given extension
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @param  {String}  fileName
			 * @param  {Array}  exts
			 * @return {Boolean}
			 */
			hasExtension: function (fileName, exts) {
				return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
			},

			/**
			 * Validates the filename to see if it has an allowed extension
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 */
			validate: function (cb) {
				picker.output.innerHTML = '';

				if ( (picker.file && !picker.file.type.match('image.*')) || !picker.hasExtension(picker.fileName, options.extensions) ) {
					picker.output.textContent = 'invalid file type';
				} else {
					if ( cb && typeof cb === 'function' ) {
						cb.apply(picker, []);
					}
				}
			},

			/**
			 * Formats the filename and starts the preview proces
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @param  {Object} evt jQuery event object
			 */
			select: function (evt) {
				var e = evt || window.event;

				picker.fileName = (e.target ? e.target : e.srcElement).value.replace('C:\\fakepath\\', '').toLowerCase();
				picker.file = (window.FileReader ? e.target.files[0] : null);

				picker.trigger('fp:select');

				if ( !picker.file || !picker.preview && picker.output ) {
					picker.output.textContent = picker.fileName;
					return;
				}

				picker.reader = new FileReader();
				picker.validate(function () {
					this.render().read();
				});
			},

			/**
			 * Renders the image preview
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @return {Object} picker object
			 */
			render: function () {
				this.reader.onload = function (e) {
					if ( picker.output ) {
						picker.output.innerHTML = ['<img src="', e.target.result,'" title="', encodeURIComponent(picker.file.name), '">'].join('');
					}
					picker.trigger('fp:render');
				};
				return this;
			},

			/**
			 * Reads the file as data URL
			 *
			 * @author Boye Oomens <boye@e-sites.nl>
			 * @return {Object} picker object
			 */
			read: function () {
				this.reader.readAsDataURL( this.file );
				return this;
			}
		};

		return picker.init();
	}

	/**
	 * jQuery / Zepto plugin wrapper
	 */
	(function () {
		var $ = window.Zepto || window.jQuery;

		if ( !$ || !$.data ) {
			return;
		}

		$.fn.filepicker = function (options) {
			var instance,
				self = this,
				opts = $.extend(options, {
					target: this[0]
				});

			self.each(function () {
				instance = new Filepicker(opts);

				// Override default event trigger
				// and pass Eternity instance as extra callback argument
				instance.trigger = function (event) {
					self.trigger(event, [self.data('Filepicker')]);
				};

				self.data('Filepicker', instance);
			});

			return self;
		};
	}());

	/**
	 * Expose `Filepicker`
	 */

	// amd export
	if ( typeof define === 'function' && define.amd ) {
		define(function () {
			return Filepicker;
		});
	}
	// browser window
	else {
		window.Filepicker = Filepicker;
	}

}(this, this.document, jQuery));